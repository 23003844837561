import { FORM_MODE } from '@/util/common-constant';
import { Position } from '@vue-flow/core';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.title = 'Thêm bước mới';
    state.step = {
      source: null,
      target: null,
      roleId: null,
    };
  },
  setFormValueFlow(state, { data, formMode }) {
    state.visibleFlow = true;
    state.formMode = formMode;
    state.list = data.step;
    state.flow = {
      id: data.id,
      name: data.name,
      status: data.status,
      description: data.description,
      tttt: data.tttt,
    };
  },
  preCreateFlow(state) {
    state.visibleFlow = true;
    state.formMode = FORM_MODE.CREATE;
    state.step = null;
    state.flow = {
      name: '',
      status: 1,
      description: '',
    };
    state.list = [
      {
        id: 'start',
        type: 'input',
        label: 'Bắt đầu',
        position: { x: 0, y: 50 },
        sourcePosition: Position.Right,
        isNode: true,
      },
      {
        id: 'end',
        type: 'output',
        label: 'Kết thúc',
        position: { x: 400, y: 50 },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        isNode: true,
      },
    ];
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  setAllFlow(state, data) {
    state.listAll = data.map((e) => ({ value: e.id, label: e.name }));
  },
  setFormValue(state, { step }) {
    state.formMode = FORM_MODE.UPDATE;
    state.visible = true;
    state.title = 'Sửa bước';
    state.step = {
      ...step,
    };
  },
};
