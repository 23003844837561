import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  getAll(state, data) {
    state.listAll = data.map((e) => ({ value: e.id, label: e.name }));
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.clip = {
      id: null,
      categoryId: null,
      title: '',
      imgPath: [],
      thumbnailPath: [],
      videoPath: [],
      tag: '',
      status: 1,
      createdTime: '',
      createdBy: '',
      modifiedTime: '',
      modifiedBy: '',
      cpId: null,
      viewTotal: 0,
      playTotal: 0,
      commentTotal: 0,
      likeTotal: 0,
      isFree: 0,
      isHot: 0,
      isNews: 0,
      description: '',
      publishTime: new Date(),
      approvedBy: '',
    };
  },
  setFormValue(state, { clip, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.clip = {
      ...clip,
      thumbnailPath: [clip.thumbnailPath],
      videoPath: [clip.videoPath],
      imgPath: [clip.imgPath],
    };
  },
};
