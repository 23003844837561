/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import { JSEncrypt } from 'jsencrypt';
import { PUBLIC_KEY, SECRET } from '@/config/key';
import CryptoJS from 'crypto-js';
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import _ from 'lodash';
import format from 'date-fns/format';

const randomChar = (size) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < size) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const encryptData = (data, publicKey = PUBLIC_KEY) => {
  let encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  return encryptor.encrypt(data);
};

const hashData = (payload, secret = SECRET) => {
  return CryptoJS.SHA256(JSON.stringify(payload) + secret).toString();
};

const formatDate = (date, formatType) => {
  if (_.isNil(date)) return '';
  return format(new Date(date), formatType || 'dd/MM/yyyy HH:mm:ss');
};

const formatSource = (data) => {
  if (data.includes('http'))
    return `<a href=${data} target="_blank" class="text-blue-500">Xem chi tiết</a>`;
  return data;
};

const checkResponse = (
  response,
  success = () => {},
  fail = () => {
    notification.error({
      message: 'Thông báo',
      description: response.message || 'Lỗi',
      duration: 4,
    });
  }
) => {
  if (response.code === '200' || response?.data?.status === 'success') {
    success(response.data);
  } else {
    fail(response.data);
  }
};

const forEachNested = (parent, cb) => {
  if (!cb) return;
  cb(parent);
  if (parent.children && parent.children.length > 0) {
    parent.children.forEach((p) => forEachNested(p, cb));
  }
};

const greaterThanCurrent = (current) =>
  current && current > dayjs().endOf('day');

const lessThanCurrent = (current) =>
  current && current <= dayjs().endOf('day').subtract(1, 'days');

const getText = (value, array) => {
  const s = array.filter((s) => s.value === value);
  if (s && s.length) return s[0].label;
  return '';
};

export function checkIsAdmin(payload) {
  return payload === null ? true : false;
}

export function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const censorNumber = (value) => {
  return value.substring(0, 3) + '****' + value.substring(7, value.length - 7);
};

const roundNumber = (number, digit = 2) => {
  if (_.isNil(number)) return null;
  return parseFloat(number.toFixed(digit));
};

const flatten = (array, field) => {
  return array.reduce((newArr, obj) => {
    if (field) {
      newArr.push(obj[field]);
    } else {
      newArr.push(obj);
    }
    if (obj.children) {
      return newArr.concat(flatten(obj.children, field));
    }
    return newArr;
  }, []);
};

const addToList = (list, data) => {
  return [
    ...list.slice(0, list.length - 1),
    data,
    ...list.slice(list.length - 1),
  ];
};

const addEdge = (list, source, target, updatable = true) => {
  const id = randomChar(6);
  list = addToList(list, {
    id: id,
    source: source,
    target: target,
    updatable: updatable,
  });
  return list;
};

const convertDataFlow = (list, isEdit) => {
  let response = {
    nodes: [],
    connections: [],
  };

  response.nodes = list
    .filter((e) => e.isNode)
    .map((e) => ({
      ...e,
      id: isEdit ? e.idDb : null,
      code: e.id,
      name: e.label,
      position: JSON.stringify(e.position),
    }));
  response.connections = list
    .filter((e) => !e.isNode)
    .map((e) => ({ ...e, id: null, code: e.id }));
  console.log(response);
  return response;
};

export {
  ellipsis,
  encryptData,
  hashData,
  formatDate,
  formatSource,
  checkResponse,
  forEachNested,
  greaterThanCurrent,
  lessThanCurrent,
  getText,
  censorNumber,
  roundNumber,
  flatten,
  randomChar,
  addToList,
  addEdge,
  convertDataFlow,
};
