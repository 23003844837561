import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { FORM_MODE } from '@/util/common-constant';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { showConfirm } from '@/util/confirm';
import { MESSAGE_DATA_APPROVAL, MESSAGES } from '@/util/message-notification';
const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  data_approval: {},
  api: ConstantAPI.data_approval,
  visible: false,
});

const actions = {
  async getSearch({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.ALL);
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getAll({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.ALL);
      return commit('setAll', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async preUpdate({ commit }, row) {
    commit('setFormValue', {
      fields: row,
      formMode: FORM_MODE.UPDATE,
    });
  },
  async approval({ commit }, payload) {
    try {
      if (await showConfirm(MESSAGE_DATA_APPROVAL.DATA_APPROVAL_001)) {
        commit('setLoading', true);
        const response = await DataService.patch(
          `${ConstantAPI.data_approval.APPROVAL.url}/${payload.id}`
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: MESSAGES.MESSAGE_001,
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
