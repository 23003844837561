import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/components/custom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@vue-flow/controls/dist/style.css';
import '@vue-flow/core/dist/style.css';
import '@vue-flow/core/dist/theme-default.css';
import '@vue-flow/node-resizer/dist/style.css';
import 'bootstrap';
import { DataService } from '@/dataService/dataService';
import { Main } from '@/view/styled';
import mitt from 'mitt';
import './assets/tailwind.css';

export const emitter = mitt();
app.config.productionTip = false;
app.use(store);
app.use(router);
app.component('cds-main', Main);
app.config.globalProperties.$callApiSearch = DataService.callApiSearch;
app.config.globalProperties.$callApi = DataService.callApi;
app.config.globalProperties.$get = DataService.get;
app.config.globalProperties.$post = DataService.post;
app.config.globalProperties.$put = DataService.put;
app.config.globalProperties.$patch = DataService.patch;
app.config.globalProperties.$delete = DataService.delete;
app.config.globalProperties.$emitter = emitter;
app.mount('#app');
