import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAll(state, data) {
    state.listAll = data.map((e) => ({ value: e.id, label: e.name }));
    state.loading = false;
  },
  preCreate(state, parentId = null) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.agency = {
      name: '',
      type: '',
      description: '',
      status: 1,
      parentId: Number(parentId),
      hasDataset: null,
      hasDatasetSql: null,
      internalShare: null,
      share: null,
      ngsp: null,
      lgsp: null,
      regulations: null,
    };
  },
  setFormValue(state, { agency, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.agency = {
      ...agency,
    };
  },
};
