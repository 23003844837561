import {flatten} from '@/util/common-utils';
import {HIDDEN_MENUS} from '@/util/common-constant';

export default {
  loginBegin(state) {
    state.loading = true;
  },
  getUserInfoSuccess(state, data) {
    data.allMenu = flatten(data.menus || [], 'path');
    state.loading = false;
    localStorage.setItem('allMenu', JSON.stringify(data.allMenu || []));
    // ẩn menu khỏi side bar
    data.menus.forEach((value) => {
      if (value.children) {
        let menusData;
        menusData = value.children.filter((e) => {
          return !HIDDEN_MENUS.includes(e.path);
        });
        value.children = menusData;
      }
    });
    state.userInfo = data;
    state.error = null;
  },
  setNotifications(state, data) {
    state.notifications = data;
  },
  loginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  logoutSuccess(state) {
    state.loading = false;
    state.tokenInfo = null;
    state.userInfo = null;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  routerLoaded(state) {
    state.routerLoaded = true;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },

  setUpLoading(state, loading) {
    state.uploading = loading;
  },
};
