<template>
  <div>
    <a-row :gutter="20">
      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item :label="fromLabel" :name="fromName">
          <DatePickerWrapper>
            <a-date-picker
              v-model:value="valueFrom"
              @change="dateFromChange"
              :disabled-date="disabledFromDate"
              :placeholder="fromLabel"
              :format="format"
              class="w-100"
              size="small"
            >
            </a-date-picker>
          </DatePickerWrapper>
        </a-form-item>
      </a-col>
      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item :label="toLabel" :name="toName">
          <DatePickerWrapper>
            <a-date-picker
              v-model:value="valueTo"
              @change="dateToChange"
              :disabled-date="disabledToDate"
              :placeholder="toLabel"
              :format="format"
              class="w-100"
              size="small"
            >
            </a-date-picker>
          </DatePickerWrapper>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { DatePickerWrapper } from '../date-picker/style';
import VueTypes from 'vue-types';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'FromToDate',
  components: {
    DatePickerWrapper,
  },
  props: {
    dateFrom: VueTypes.object.def(),
    dateTo: VueTypes.object.def(),
    format: VueTypes.string.def('DD/MM/YYYY'),
    disabledDate: VueTypes.func.def(() => false),
    fromLabel: VueTypes.string.def('Từ ngày'),
    fromName: VueTypes.string,
    toLabel: VueTypes.string.def('Đến ngày'),
    toName: VueTypes.string,
  },
  setup(props, { emit }) {
    const valueFrom = ref(props.dateFrom ? dayjs(props.dateFrom) : null);
    const valueTo = ref(props.dateTo ? dayjs(props.dateTo) : null);
    const disabledDate = ref(props.disabledDate);
    const disabledToDate = (current) => {
      // Can not select days before today and today
      return (
        current &&
        (disabledDate.value(current) ||
          (valueFrom.value && current < valueFrom.value))
      );
    };
    const disabledFromDate = (current) => {
      // Can not select days before today and today
      return (
        current &&
        (disabledDate.value(current) ||
          (valueTo.value && current > valueTo.value))
      );
    };
    const dateFromChange = (val) =>
      emit('update:dateFrom', val ? val.toDate() : null);
    const dateToChange = (val) =>
      emit('update:dateTo', val ? val.toDate() : null);
    watch(
      () => props.dateFrom,
      (val) => (valueFrom.value = val ? dayjs(val) : null)
    );
    watch(
      () => props.dateTo,
      (val) => (valueTo.value = val ? dayjs(val) : null)
    );
    return {
      valueFrom,
      valueTo,
      dateFromChange,
      dateToChange,
      disabledToDate,
      disabledFromDate,
    };
  },
  methods: {
    change(val) {
      this.$emit(
        'update:value',
        (val || []).map((d) => d.toDate())
      );
    },
  },
  watch: {
    value(val) {
      this.valueDate = (val || []).map((d) => dayjs(d));
    },
  },
});
</script>

<style lang="scss" scoped>
.ant-picker-large {
  padding: 10.5px 11px 10.5px !important;
}
</style>
