import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {FORM_MODE} from "@/util/common-constant";
import {showConfirm} from "@/util/confirm";
import {checkResponse} from "@/util/common-utils";
import {notification} from "ant-design-vue";

const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  search: {},
  api: ConstantAPI.key_config,
  visible: false,
  excelUrl: null,
});

const actions = {
  async getSearch({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.SEARCH);
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async setVisible({ state }, visible) {
    state.visible = visible;
  },
  async preUpdate({ commit }, row) {
    commit('setFormValue', {
      search: row,
      formMode: FORM_MODE.UPDATE,
    });
  },
  async update({ commit, state }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const response = await DataService.callApiSearch(
            state.api.UPDATE,
            {
              ...payload,
              id: state.search.id,
            },
            null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Cập nhật từ khóa thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async preCreate({ commit }) {
    commit('preCreate');
  },
  async create({ commit, state }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const response = await DataService.callApiSearch(
            state.api.CREATE,
            payload,
            null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Thêm mới Từ khóa thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async report({ commit, state }, payload) {
    try {
      this.excelUrl = null
      commit('setLoading', true);
      const response = await DataService.callApiSearch(
          state.api.EXPORT,
          {
            ...payload,
            id: state.search.id,
          },
          null
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        window.open(response.data.data)
        notification.success({
          message: 'Thông báo',
          description: 'Export thành công',
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
