import store from '@/vuex/store';

const guard = (from) => {
  if (store.getters.allMenu.includes(from.path)) {
    return true;
  }
  return { path: '404' };
};

const adminRoutes = [
  {
    path: '',
    redirect: () => {
      return '/home';
    },
  },
  {
    path: 'home',
    name: 'dashboard',
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ '@/view/manager/home/Home.vue'
      ),
  },
  {
    path: 'settings',
    name: 'settings',
    component: () =>
      import(
        /* webpackChunkName: "Settings" */ '@/view/pages/settings/Settings.vue'
      ),
    children: [
      {
        path: 'profile-settings',
        name: 'profile-settings',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      {
        path: 'profile-settings/profile',
        name: 'set-profile',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      {
        path: 'profile-settings/account',
        name: 'set-account',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Account.vue'
          ),
      },
      {
        path: 'profile-settings/password',
        name: 'set-password',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Password.vue'
          ),
      },
    ],
  },
  {
    path: 'clip-manager',
    name: 'clip-management',
    children: [
      {
        path: 'upload',
        name: 'clip-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/clip/clip/Clip.vue'
          ),
      },
      {
        path: 'approval',
        name: 'approval-clip',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/clip/approval/Approval.vue'
          ),
      },
    ],
  },
  {
    path: 'system',
    name: 'system',
    children: [
      {
        path: 'menu',
        name: 'menu-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/menu/Menu.vue'
          ),
      },
      {
        path: 'role',
        name: 'role-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/role/Role.vue'
          ),
      },
      {
        path: 'user',
        name: 'user-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/user/User.vue'
          ),
      },
      {
        path: 'agency',
        name: 'agency',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/agency/Agency.vue'
          ),
      },
      {
        path: 'agency/detail',
        name: 'agencyList',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/agency/AgencyDetail.vue'
          ),
      },
    ],
  },
  {
    path: 'portal',
    name: 'portal',
    children: [
      {
        path: 'article',
        name: 'article',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/article/Article.vue'
          ),
      },
      {
        path: 'file',
        name: 'file',
        beforeEnter: [guard],
        component: () =>
            import(
                /* webpackChunkName: "Manager" */ '@/view/manager/files/File.vue'
                ),
      },
      {
        path: 'datasets',
        name: 'datasets',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/dataset/Dataset.vue'
          ),
      },
      {
        path: 'data-approval',
        name: 'data-approval',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/manager/dataApproval/DataApproval.vue'
          ),
      },
    ],
  },
  {
    path: 'config',
    name: 'config',
    children: [
      {
        path: 'fields',
        name: 'fields',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/fields/Fields.vue'
          ),
      },
      {
        path: 'licenses',
        name: 'licenses',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/license/License.vue'
          ),
      },
      {
        path: 'category',
        name: 'category-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/category/Category.vue'
          ),
      },
      {
        path: 'approve-flow',
        name: 'approve-flow',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/flow/Flow.vue'
          ),
      },
      {
        path: 'agency',
        name: 'agency-config',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/agencyConfig/AgencyConfig.vue'
          ),
      },
    ],
  },
  {
    path: 'search',
    name: 'search',
    beforeEnter: [guard],
    component: () =>
        import(/* webpackChunkName: "Manager" */ '@/view/manager/search/Search.vue'),
  },
  {
    path: 'search-his',
    name: 'search-his',
    beforeEnter: [guard],
    component: () =>
        import(/* webpackChunkName: "Manager" */ '@/view/manager/searchHis/SearchHis.vue'),
  },
  {
    path: 'config-key',
    name: 'config-key',
    beforeEnter: [guard],
    component: () =>
        import(/* webpackChunkName: "Manager" */ '@/view/manager/searchConfigKey/ConfigKey.vue'),
  },
];

export default adminRoutes;
