import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { FORM_MODE } from '@/util/common-constant';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { showConfirm } from '@/util/confirm';

const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  dataset: {},
  api: ConstantAPI.dataset,
  visible: false,
  manageData: [],
  manageDataPie: [],
  provideData: [],
  fieldData: [],
  totalFile: {
    total: 0,
  },
  totalFileUpload: {
    total: 0,
  },
  totalFileDownload: {
    total: 0,
  },
  totalAgenciesUploaded: {
    total: 0,
  },
  topFileUpload: [],
  topAgencies: [],
  fileExtensions: [],
});

const actions = {
  async getSearch({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.SEARCH);
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getAll({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.ALL);
      return commit('getAll', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async preCreate({ commit }) {
    commit('preCreate');
  },
  async preUpdate({ commit, state }, row) {
    const response = await DataService.get(`${state.api.SEARCH.url}/${row.id}`);
    commit('setFormValue', {
      dataset: response.data,
      formMode: FORM_MODE.UPDATE,
    });
  },
  async preView({ commit, state }, row) {
    const response = await DataService.get(`${state.api.SEARCH.url}/${row.id}`);
    commit('setFormValue', {
      dataset: response.data,
      formMode: FORM_MODE.VIEW,
    });
  },
  async create({ commit, state }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const response = await DataService.callApi(
          state.api.CREATE,
          {
            ...payload,
            type: 1,
            files: payload.files.map((e) => ({ url: e })),
            datasetFields: payload.datasetFields.map((e) => ({ fieldId: e })),
          },
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Thêm mới Giấy phép thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async update({ commit, state }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const response = await DataService.callApi(
          state.api.UPDATE,
          {
            ...payload,
            id: state.dataset.id,
            files: payload.files.map((e) => ({ url: e })),
            datasetFields: payload.datasetFields.map((e) => ({ fieldId: e })),
          },
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Cập nhật Dữ liệu thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete({ state }, rows) {
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.DELETE.url + '/' + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa Dữ liệu thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async getReportByAgency({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.REPORT_BY_AGENCY);
      return commit('getReportByAgency', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getManageData({ commit }) {
    try {
      const response = await DataService.callApi(ConstantAPI.chart.MANAGE_DATA);
      return commit('setManageData', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getManageDataSearch({ commit }, {params}) {
    try {
      const response = await DataService.callApiSearch(ConstantAPI.search.TOP_KEY_RANGE_TIME, null, params);
      return commit('setManageData', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getManageDataSearchPie({ commit }) {
    try {
      const response = await DataService.callApiSearch(ConstantAPI.search.TOP_KEY, null,null);
      console.log(response.data)
      return commit('setManageDataPie', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getProvideData({ commit }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.chart.PROVIDE_DATA
      );
      return commit('setProvideData', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getFieldData({ commit }) {
    try {
      const response = await DataService.callApi(ConstantAPI.chart.FIELD_DATA);
      return commit('setFieldData', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getTotalFile({ commit }) {
    try {
      const response = await DataService.callApi(ConstantAPI.chart.TOTAL_FILE);
      return commit('setTotalFile', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getTotalFileUpload({ commit }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.chart.TOTAL_FILE_UPLOAD
      );
      return commit('setTotalFileUpload', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getTotalFileDownload({ commit }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.chart.TOTAL_FILE_DOWNLOAD
      );
      return commit('setTotalFileDownload', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getTotalAgenciesUploaded({ commit }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.chart.TOTAL_AGENCIES_UPLOADED
      );
      return commit('setTotalAgenciesUploaded', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getTopFileUpload({ commit }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.chart.TOP_FILE_UPLOAD
      );
      return commit('setTopFileUpload', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getTopAgencies({ commit }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.chart.TOP_AGENCIES
      );
      return commit('setTopAgencies', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getFileExtension({ commit }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.chart.FILE_EXTENSION
      );
      return commit('setFileExtensions', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async setVisible({ state }, visible) {
    state.visible = visible;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
