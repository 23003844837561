export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  setAll(state, data) {
    state.listAll = data.map((e) => ({ value: e.id, label: e.title }));
    state.loading = false;
  },
};
