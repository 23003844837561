import axios from 'axios';
import { checkResponse, formatDate } from '../util/common-utils';
import { notification } from 'ant-design-vue';
import store from '@/vuex/store';
import ConstantAPI from '@/config/ConstantAPI';

const API_ENDPOINT = window.VUE_APP_API_ENDPOINT;
const USERNAME = window.VUE_APP_AUTH_CLIENT;
const PASSWORD = window.VUE_APP_AUTH_SECRET;
const API_SEARCH_ENDPOINT = window.VUE_APP_API_SEARCH_ENDPOINT;
const BASIC_AUTH = `Basic ${btoa(USERNAME + ':' + PASSWORD)}`;

const httpClient = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});
const httpClientSearch = axios.create({
  baseURL: API_SEARCH_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
httpClient.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const { headers } = config;
  if (/[\w]*\/oauth\/token/g.test(config.url)) {
    config.headers = { ...headers, Authorization: BASIC_AUTH };
  } else {
    config.headers = {
      ...headers,
    };
  }
  Object.keys(config.params || {}).forEach((k) => {
    const date = config.params[k];
    if (date instanceof Date) {
      config.params[k] = formatDate(date, 'yyyy-MM-dd HH:mm:ss');
    }
  });
  return config;
});

let refreshing = false;

httpClient.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const config = error.config;
    if (/[\w]*\/oauth\/token/g.test(config.url) || config.retry) {
      return Promise.reject(error.response.data);
    }
    if (error.response) {
      let errorMessage =
        error.response.data === null
          ? 'Ngoại lệ hệ thống nội bộ, vui lòng liên hệ với quản trị trang web'
          : error.response.data.message;
      switch (error.response.status) {
        case 404:
          notification.error({
            message: 'Thông báo',
            description: 'Xin lỗi, tài nguyên không được tìm thấy',
            duration: 4,
          });
          break;
        case 403:
          notification.error({
            message: 'Thông báo',
            description: 'Tài khoản không có quyền truy cập tài nguyên',
            duration: 4,
          });
          break;
        case 401:
          try {
            if (refreshing && !config.retry) {
              for (let i = 0; i < 10; i++) {
                await new Promise((r) => setTimeout(r, 100));
                if (!refreshing) break;
              }
              return httpClient(config);
            }
            config.retry = true;
            refreshing = true;
            const response = await DataService.callApi(
              ConstantAPI.auth.LOGIN,
              new URLSearchParams({
                grant_type: 'refresh_token',
                refresh_token: store.getters.accessToken,
              }),
              null,
              {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: BASIC_AUTH,
              }
            );
            checkResponse(
              response,
              async () => {
              },
              async () => {
                await store.dispatch('logOut');
                refreshing = false;
                return Promise.reject(response);
              }
            );
            refreshing = false;
            return httpClient(config);
          } catch (_error) {
            await store.dispatch('logOut');
            refreshing = false;
            return Promise.reject(_error);
          }
        default:
          notification.error({
            message: 'Thông báo',
            description: errorMessage,
            duration: 4,
          });
          break;
      }
    }
    return Promise.reject(
      error.response ? error.response.data : error.response
    );
  }
);

class DataService {
  static callApi(api, data, params, headers) {
    return httpClient({
      method: api.method,
      url: api.url,
      data,
      params,
      headers,
    });
  }
  static callApiSearch(api, data, params, headers) {
    return httpClientSearch({
      method: api.method,
      url: api.url,
      data,
      params,
      headers,
    });
  }

  static upload(api, data, config) {
    return httpClient({
      method: api.method,
      url: api.url,
      data,
      ...config,
    });
  }

  static getConfig(path = '', params, headers, responseType) {
    return httpClient.get(path, { headers, params, responseType });
  }

  static get(path = '', params, headers) {
    return httpClient.get(path, { headers, params });
  }

  static post(path = '', data = {}, headers) {
    return httpClient.post(path, data, { headers });
  }

  static patch(path = '', data = {}, headers) {
    return httpClient.patch(path, data, { headers });
  }

  static delete(path = '', data = {}, headers) {
    return httpClient.delete(path, data, { headers });
  }

  static put(path = '', data = {}, headers) {
    return httpClient.put(path, data, { headers });
  }
}

export { DataService };
