export default {
  report_service: {
    OVERVIEW: {
      url: '/overview',
      method: 'GET'
    },
    OVERVIEW_LINE: {
      url: '/chart',
      method: 'GET'
    }
  },
  role: {
    GET_ALL: {
      url: '/role/get-all',
      method: 'GET'
    },
    SEARCH: {
      url: '/role',
      method: 'GET'
    },
    CREATE: {
      url: '/role',
      method: 'POST'
    },
    UPDATE: {
      url: '/role',
      method: 'PUT'
    },
    DELETE: {
      url: '/role',
      method: 'DELETE'
    }
  },
  role_menu: {
    GET_BY_ROLE: {
      url: '/role-menu',
      method: 'GET'
    }
  },
  user_role: {
    GET_BY_USER: {
      url: '/user-role',
      method: 'GET'
    }
  },
  key_config: {
    SEARCH: {
      url: '/schedule_search',
      method: 'GET'
    },
    CREATE: {
      url: '/schedule_search/insert',
      method: 'POST'
    },
    UPDATE: {
      url: '/schedule_search/update',
      method: 'POST'
    },
    EXPORT: {
      url: '/report/excel',
      method: 'GET'
    }
  },
  clip: {
    ALL: {
      url: '/clip/get-all',
      method: 'GET'
    },
    SEARCH: {
      url: '/clip',
      method: 'GET'
    },
    CREATE: {
      url: '/clip',
      method: 'POST'
    },
    UPDATE: {
      url: '/clip',
      method: 'PUT'
    },
    DELETE: {
      url: '/clip',
      method: 'DELETE'
    },
    APPROVAL: {
      url: '/clip/approve',
      method: 'DELETE'
    },
    CANCEL_APPROVAL: {
      url: '/clip/not-approve',
      method: 'DELETE'
    },
    TOP: {
      url: '/clip/on-top',
      method: 'DELETE'
    },
    STATISTICAL_FILE: {
      url: '/clip/statistical-file',
      method: 'GET'
    },
    STATISTICAL: {
      url: '/clip/statistical',
      method: 'GET'
    }
  },
  cp: {
    ALL: {
      url: '/cp/get-all',
      method: 'GET'
    },
    SEARCH: {
      url: '/cp',
      method: 'GET'
    },
    CREATE: {
      url: '/cp',
      method: 'POST'
    },
    UPDATE: {
      url: '/cp',
      method: 'PUT'
    },
    DELETE: {
      url: '/cp',
      method: 'DELETE'
    }
  },
  cpGroup: {
    ALL: {
      url: '/cp-group/get-all',
      method: 'GET'
    }
  },
  category: {
    ALL: {
      url: '/category/get-all',
      method: 'GET'
    },
    SEARCH: {
      url: '/category',
      method: 'GET'
    },
    CREATE: {
      url: '/category',
      method: 'POST'
    },
    UPDATE: {
      url: '/category',
      method: 'PUT'
    },
    DELETE: {
      url: '/category',
      method: 'DELETE'
    }
  },
  dataset: {
    ALL: {
      url: '/datasets/get-all',
      method: 'GET'
    },
    SEARCH: {
      url: '/datasets',
      method: 'GET'
    },
    CREATE: {
      url: '/datasets',
      method: 'POST'
    },
    UPDATE: {
      url: '/datasets',
      method: 'PUT'
    },
    DELETE: {
      url: '/datasets',
      method: 'DELETE'
    },
    REPORT_BY_AGENCY: {
      url: '/datasets/report-by-agency',
      method: 'GET'
    }
  },
  fields: {
    ALL: {
      url: '/fields/get-all',
      method: 'GET'
    },
    SEARCH: {
      url: '/fields',
      method: 'GET'
    },
    CREATE: {
      url: '/fields',
      method: 'POST'
    },
    UPDATE: {
      url: '/fields',
      method: 'PUT'
    },
    DELETE: {
      url: '/fields',
      method: 'DELETE'
    }
  },
  license: {
    ALL: {
      url: '/licenses/get-all',
      method: 'GET'
    },
    SEARCH: {
      url: '/licenses',
      method: 'GET'
    },
    CREATE: {
      url: '/licenses',
      method: 'POST'
    },
    UPDATE: {
      url: '/licenses',
      method: 'PUT'
    },
    DELETE: {
      url: '/licenses',
      method: 'DELETE'
    }
  },

  menu: {
    SEARCH: {
      url: '/menu',
      method: 'GET'
    },
    CREATE: {
      url: '/menu',
      method: 'POST'
    },
    UPDATE: {
      url: '/menu',
      method: 'PUT'
    },
    DELETE: {
      url: '/menu',
      method: 'DELETE'
    }
  },
  user: {
    SEARCH: {
      url: '/user',
      method: 'GET'
    },
    CREATE: {
      url: '/user',
      method: 'POST'
    },
    UPDATE: {
      url: '/user',
      method: 'PUT'
    },
    DELETE: {
      url: '/user',
      method: 'DELETE'
    },
    CHECK_USERNAME_EXISTED: {
      url: '/user/check',
      method: 'GET'
    },
    GET_USER_INFO: {
      url: '/user/get-user-info',
      method: 'GET'
    },
    GET_NOTIFICATIONS: {
      url: '/notify',
      method: 'GET'
    },
    MARK_READ_NOTIFICATION: {
      url: '/notify/seen',
      method: 'PATCH'
    },
    PATCH_UPDATE: {
      url: '/user',
      method: 'PATCH'
    }
  },
  auth: {
    LOGIN: {
      url: '/oauth/token',
      method: 'POST'
    },
    LOGOUT: {
      url: '/oauth/token/revoke',
      method: 'DELETE'
    },
    PATCH_UPDATE: {
      url: '/user',
      method: 'PATCH'
    }
  },
  upload_service: {
    UPLOAD: {
      url: '/upload',
      method: 'POST'
    }
  },
  agency: {
    SEARCH: {
      url: '/agency',
      method: 'GET'
    },
    ALL: {
      url: '/agency/get-all',
      method: 'GET'
    },
    CREATE: {
      url: '/agency',
      method: 'POST'
    },
    DELETE: {
      url: '/agency',
      method: 'DELETE'
    },
    UPDATE: {
      url: '/agency',
      method: 'PUT'
    }
  },
  flow: {
    SEARCH: {
      url: '/approve-flow',
      method: 'GET'
    },
    ALL: {
      url: '/approve-flow/get-all',
      method: 'GET'
    },
    BY_ID: {
      url: '/approve-flow',
      method: 'GET'
    },
    CREATE: {
      url: '/approve-flow',
      method: 'POST'
    },
    DELETE: {
      url: '/approve-flow',
      method: 'DELETE'
    },
    UPDATE: {
      url: '/approve-flow',
      method: 'PUT'
    }
  },
  articles: {
    ALL: {
      url: '/articles/get-all',
      method: 'GET'
    },
    SEARCH: {
      url: '/articles',
      method: 'GET'
    },
    CREATE: {
      url: '/articles',
      method: 'POST'
    },
    UPDATE: {
      url: '/articles',
      method: 'PUT'
    },
    DELETE: {
      url: '/articles',
      method: 'DELETE'
    },
    APPROVAL: {
      url: '/articles/approve',
      method: 'DELETE'
    },
    CANCEL_APPROVAL: {
      url: '/articles/not-approve',
      method: 'DELETE'
    },
    TOP: {
      url: '/articles/on-top',
      method: 'DELETE'
    }
  },
  chart: {
    MANAGE_DATA: {
      url: '/agency/get-chart',
      method: 'GET'
    },
    PROVIDE_DATA: {
      url: 'agency/get-chart-dataset',
      method: 'GET'
    },
    FIELD_DATA: {
      url: '/fields/chart-field-dataset',
      method: 'GET'
    },
    TOTAL_FILE: {
      url: '/file/total-files',
      method: 'GET'
    },
    TOTAL_FILE_UPLOAD: {
      url: '/file/total-files-upload-by-month',
      method: 'GET'
    },
    TOTAL_FILE_DOWNLOAD: {
      url: '/file/total-files-download-by-month',
      method: 'GET'
    },
    TOTAL_AGENCIES_UPLOADED: {
      url: '/file/total-agencies-uploaded',
      method: 'GET'
    },
    TOP_FILE_UPLOAD: {
      url: '/file/top-10',
      method: 'GET'
    },
    TOP_AGENCIES: {
      url: '/file/top-10-agencies',
      method: 'GET'
    },
    FILE_EXTENSION: {
      url: '/file/files-extension',
      method: 'GET'
    }
  },
  data_approval: {
    ALL: {
      url: '/datasets/approval',
      method: 'GET'
    },
    APPROVAL: {
      url: '/datasets/approval',
      method: 'PATCH'
    }
  },
  search: {
    SEARCH: {
      url: '/search',
      method: 'GET'
    },
    SEARCH_HIS: {
      url: '/report',
      method: 'GET'
    },
    TOP_KEY: {
      url: '/top_key',
      method: 'GET'
    },
    TOP_KEY_RANGE_TIME: {
      url: '/top_key/day',
      method: 'GET'
    },
  },
  file: {
    ALL: {
      url: '/template-file',
      method: 'GET'
    },
    ALL_BY_ID: {
      url: '/template-file',
      method: 'GET'
    },
    DELETE: {
      url: '/template-file',
      method: 'DELETE'
    },
    CREATE: {
      url: '/template-file',
      method: 'POST'
    },
    UPDATE: {
      url: '/template-file',
      method: 'PUT'
    }
  }
};
