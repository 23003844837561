import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';
import { showConfirm } from '@/util/confirm';
import {
  addEdge,
  addToList,
  checkResponse,
  convertDataFlow,
  randomChar,
} from '@/util/common-utils';
import { FORM_MODE } from '@/util/common-constant';
import { Position } from '@vue-flow/core';
import { DataService } from '@/dataService/dataService';
import { notification } from 'ant-design-vue';
import ConstantAPI from '@/config/ConstantAPI';

const state = () => ({
  list: [
    {
      id: 'start',
      type: 'input',
      label: 'Bắt đầu',
      position: { x: 0, y: 50 },
      sourcePosition: Position.Right,
      isNode: true,
    },
    {
      id: 'end',
      type: 'output',
      label: 'Kết thúc',
      position: { x: 400, y: 50 },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      isNode: true,
    },
  ],
  listAll: [],
  flow: null,
  visibleFlow: false,
  step: null,
  loading: false,
  visible: false,
  visibleEdit: false,
  title: 'Thêm mới bước',
  api: ConstantAPI.flow,
});

const actions = {
  // flow
  async getAllFlow({ commit }) {
    const response = await DataService.callApi(ConstantAPI.flow.ALL);
    commit('setAllFlow', response.data);
  },

  async setVisibleFlow({ state }, visibleFlow) {
    state.visibleFlow = visibleFlow;
  },
  async preUpdateFlow({ commit }, payload) {
    try {
      commit('setLoading', true);
      const response = await DataService.get(
        ConstantAPI.flow.BY_ID.url + '/' + payload.id
      );
      let success = false;

      let data = response?.data;
      if (data) {
        const edgeEnd = data.connections.find((e) => e.target === 'end');
        const edgeStart = data.connections.find((e) => e.source === 'start');
        const node4T = data.nodes.find((e) => e.tttt);
        data.connections = data.connections.map((e) => {
          return {
            ...e,
            idDb: e.id,
            id: e.code,
            updatable: !(edgeEnd?.id === e.id && node4T),
          };
        });
        data.nodes = data.nodes.map((e) => {
          return {
            ...e,
            idDb: e.id,
            id: e.code,
            label: e.name,
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            data: {
              toolbarPosition: Position.Top,
              toolbarVisible: false,
              idNode: e.code,
              tttt: e.tttt
            },
            type: 'toolbar',
            position: JSON.parse(e.position),
            style: {
              padding: '10px',
              borderRadius: '3px',
              width: '150px',
              fontSize: '12px',
              textAlign: 'center',
              borderWidth: '1px',
              borderStyle: ' solid',
              borderColor: node4T?.id !== e.id ? '#000' : '#1C9B69FF',
              color: '#222',
              backgroundColor: '#fff',
            },
            isNode: true,
          };
        });

        const nodeStart = data.nodes.find((e) => e.id === edgeStart.target);
        const nodeEnd = data.nodes.find((e) => e.code === edgeEnd.source);
        const listDefault = [
          {
            id: 'start',
            type: 'input',
            label: 'Bắt đầu',
            position: {
              x: nodeStart.position.x - 200,
              y: nodeStart.position.y - 50,
            },
            sourcePosition: Position.Right,
            isNode: true,
          },
          {
            id: 'end',
            type: 'output',
            label: 'Kết thúc',
            position: {
              x: nodeEnd.position.x + 200,
              y: nodeEnd.position.y - 50,
            },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            isNode: true,
          },
        ];
        let step = [];
        step.push(listDefault[0]);
        step = step.concat(data.connections).concat(data.nodes);
        step.push(listDefault[1]);

        if (node4T) {
          data = { ...data, tttt: true };
        }

        commit('setFormValueFlow', {
          data: { ...data, step },
          formMode: payload.formMode,
        });
      }
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async preCreateFlow({ commit }) {
    commit('preCreateFlow');
  },
  async createFlow({ state, commit }, payload) {
    try {
      const flow = convertDataFlow(payload.listStep);
      const data = {
        ...payload,
        nodes: flow.nodes,
        connections: flow.connections,
      };
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const response = await DataService.callApi(
          state.api.CREATE,
          data,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Thêm mới Quy trình thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async updateFlow({ state, commit }, payload) {
    try {
      const flow = convertDataFlow(payload.listStep);
      const data = {
        ...payload,
        nodes: flow.nodes,
        connections: flow.connections,
      };
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const response = await DataService.callApi(
          state.api.UPDATE,
          data,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Sửa Quy trình thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteFlow({ state }, rows) {
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.DELETE.url + '/' + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa Quy trình thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  // node and connections
  async preView({ commit }, row) {
    commit('setFormValue', {
      step: row,
      formMode: FORM_MODE.VIEW,
    });
  },
  async preUpdate({ commit, state }, payload) {
    let dataEdit = state.list.find((e) => e.id === payload.id);
    commit('setFormValue', {
      step: dataEdit,
    });
  },
  async preCreate({ commit }) {
    commit('preCreate');
  },
  async setVisible({ state }, visible) {
    state.visible = visible;
  },
  async setVisibleEdit({ state }, visibleEdit) {
    state.visibleEdit = visibleEdit;
  },
  async updatePosition({ state }, listNodes) {
    for (const [i, listItem] of state.list.entries()) {
      const matchingNode = listNodes.find(
        (node) => node.id === listItem.id && node.position !== listItem.position
      );

      if (matchingNode) {
        state.list[i] = { ...listItem, position: matchingNode.position };
      }
    }
  },
  async createEdge({ state }, payload) {
    try {
      state.list = addEdge(state.list, payload.source, payload.target);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async deleteEdge({ state }, payload) {
    try {
      //remove edge
      const data = state.list.find(
        (e) =>
          e.source === payload.edge.source && e.target === payload.edge.target
      );
      if (data) {
        state.list = state.list.filter((e) => e.id !== data.id);
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async addNode4T({ state }, payload) {
    try {
      if (payload.tttt) {
        //remove edge
        const idNode = randomChar(6);
        const listData = state.list.filter((e) => e.isNode === true);
        const node = {
          label: payload.roleName,
          roleId: payload.roleId,
          flowId: state.formMode === FORM_MODE.CREATE ? null : state.flow.id,
          id: idNode,
          position: {
            x: listData[listData.length - 2].position.x + 200,
            y: 50,
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          data: {
            toolbarPosition: Position.Top,
            toolbarVisible: false,
            idNode: idNode,
          },
          type: 'toolbar',
          style: {
            padding: '10px',
            borderRadius: '3px',
            width: '150px',
            fontSize: '12px',
            textAlign: 'center',
            borderWidth: '1px',
            borderStyle: ' solid',
            borderColor: ' #1C9B69FF',
            color: '#1c9b69',
            backgroundColor: '#fff',
          },
          isNode: true,
          tttt: true,
        };
        console.log(node);
        const data = state.list.filter((e) => e.target === 'end');
        if (data) {
          state.list = state.list.filter((e) => !data.includes(e));
        }

        state.list = addToList(state.list, node);
        state.list = addEdge(state.list, idNode, 'end', false);
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async create({ commit, state }, payload) {
    try {
      const listData = state.list.filter((e) => e.isNode === true);
      const idNode = randomChar(6);
      commit('setLoading', true);
      const node = {
        label: payload.label,
        roleId: payload.roleId,
        id: idNode,
        position: { x: listData[listData.length - 2].position.x + 200, y: 50 },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: {
          toolbarPosition: Position.Top,
          toolbarVisible: false,
          idNode: idNode,
        },
        type: 'toolbar',
        style: {
          padding: '10px',
          borderRadius: '3px',
          width: '150px',
          fontSize: '12px',
          textAlign: 'center',
          borderWidth: '1px',
          borderStyle: ' solid',
          borderColor: ' #000',
          color: '#222',
          backgroundColor: '#fff',
        },
        isNode: true,
        tttt: false,
      };
      state.list[state.list.length - 1].position.x =
        state.list[state.list.length - 1].position.x + 200;
      // add node
      state.list = addToList(state.list, node);

      commit('setLoading', false);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async update({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      const indexNumber = state.list.findIndex(
        (node) => node.id === payload.id
      );

      if (indexNumber) {
        state.list[indexNumber] = {
          ...state.list[indexNumber],
          roleId: payload.roleId,
          label: payload.label,
        };
      }

      commit('setLoading', false);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async delete({ state }, payload) {
    try {
      const listEdge = state.list.filter(
        (e) =>
          e.id !== payload.id &&
          (e.target === payload.id || e.source === payload.id)
      );
      //remove all edge
      if (listEdge && listEdge.length > 0) {
        state.list = state.list.filter((item) => !listEdge.includes(item));
      }
      state.list = state.list.filter((item) => payload.id !== item.id);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
